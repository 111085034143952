import React, { Component } from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { bubbleSvg } from '../helpers';
import { lightTeal } from '../colors';

const ThisIsPresonoContainer = styled.div`
  display: flex;
  padding-top: 5%;
  position: relative;
  max-width: 1920px;
  margin: auto; 
  z-index: 1;

  @media (max-width: 1023px) {
    flex-direction: column-reverse;
  }

  @media (max-width: 900px) {
    padding-top: 8%;
  }

`;

const ImageWrapper = styled.div`
  width: 50%;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  height: 0;
  padding-top: 37.43%;
  margin: auto;

  @media (max-width: 1023px) {
    width: 85%;
    padding-top: 63.64%;
  }

  @media (max-width: 550px) {
    width: 95%;
    padding-top: 71.12%;
  }
`;

const BackgroundBubble = styled.div`
  background-image: ${bubbleSvg(lightTeal, 30)};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  width: 100%;
  height: 95%;
  left: -15%;
  right: 0;
  top: 0;
  bottom: 0;
`;

const Image = styled.div`
  background-image: url("${(props) => props.url}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

`;

// this code was used for when the image in the screen was replacable

// const ImageWrapper = styled.div`
//   width: 50%;
//   position: relative;
//   perspective: min(130vw, calc(1.3 * 1920px));
//   flex-shrink: 0;
//   flex-grow: 0;
//   height: 0;
//   padding-top: 37.43%;
//   margin: auto;

//   @media (max-width: 1023px) {
//     width: 85%;
//     padding-top: 63.64%;
//     perspective: 240vw;
//   }

//   @media (max-width: 550px) {
//     width: 95%;
//     padding-top: 71.12%;
//   }
// `;

// const ThisIsPresonoMonitor = styled.div`
//   background-image: url(/img/mockup_notebook_with_icons_new.png);
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position-y: center;
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
// `;

// const ThisIsPresonoScreen = styled.div`
//   background-image: url("${(props) => props.url}");
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position-y: center;
//   width: 64%;
//   height: 52%;
//   position: absolute;
//   left: 7.2%;
//   right: 0;
//   top: 21.5%;
//   bottom: 0;
//   transform: rotate3d(0.77,7,0.22,41.5deg); //(x,y,z,angle)
// `;

const TextContainer = styled.div`
  width: 50%;

  @media (max-width: 2120px) {
    margin-right: 100px;
  }

  @media (max-width: 1370px) {
    margin-right: 50px;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }

`;

const Headline = styled.h2`
  font-size: 60px;
  color: var(--color-white);
  text-align: right;
  white-space: pre-wrap;
  padding-top: 6%;
  margin-bottom: 0;

  @media (max-width: 1620px) {
    font-size: 55px;
  }

  @media (max-width: 1450px) {
    font-size: 47px;
  }

  @media (max-width: 1250px) {
    font-size: 44px;
  }

  @media (max-width: 1023px) {
    text-align: center;
    margin-right: 0;
    padding-top: 0;
    font-size: 50px;
  }

  @media (max-width: 550px) {
    font-size: 29px;
  }
`;

const Subtext = styled.div`
  font-size: 20px;
  color: var(--color-white);
  text-align: right;
  white-space: pre-wrap;
  padding-bottom: 17%;
  margin-top: 0;


  & p {
    margin-top: 0;
    padding-top: 20px;
    width: 90%;
    margin-left: auto;

    @media (max-width: 1023px) {
      margin: auto;
    }
  }

  @media (max-width: 1450px) {
    font-size: 19px;
  }

  @media (max-width: 1250px) {
    font-size: 17px;
  }

  @media (max-width: 1023px) {
    font-size: 20px;
    text-align: left;
    padding-bottom: 2%;
  }

  @media (max-width: 900px) {
    font-size: 18px;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    text-align: center;
  }

`;

export default class ThisIsPresono extends Component {
  static propTypes = {
    data: PropTypes.object,
  };

  render() {
    const { data } = this.props;

    return (
      <section>
        <ThisIsPresonoContainer>
          <ImageWrapper>
            <BackgroundBubble />
            <Image url={data?.thisIsPresonoImage} />
            {/* <ThisIsPresonoScreen url={data?.thisIsPresonoImageScreen} />
            <ThisIsPresonoMonitor /> */}
          </ImageWrapper>
          <TextContainer>
            <Headline>
              { data?.headlineThisIsPresono || 'HEADLINE NOT FOUND'}
            </Headline>
            <Subtext>
              <MDXRenderer>{ data?.subtextThisIsPresono || 'SUBTEXT NOT FOUND'}</MDXRenderer>
            </Subtext>
          </TextContainer>
        </ThisIsPresonoContainer>
      </section>
    );
  }
}
