import React, { Component } from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { localizedPath } from 'gatsby-theme-i18n/src/helpers';
import styled from 'styled-components';

import { bubbleSvg } from '../helpers';
import { lightTeal } from '../colors';

const TeaserContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 6%;
  padding-bottom: 2%;
  position: relative;
  z-index: 1;
  max-width: 1920px;
  margin: auto;

  @media (max-width: 1620px) {
    padding-top: 5%;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: auto;

  @media (max-width: 2120px) {
    padding-left: 100px;
  }

  @media (max-width: 1370px) {
    padding-left: 50px;
  }
`;

const Headline = styled.h1`
  font-size: 50px;
  margin-bottom: 0;
  margin-top: 0;
  color: var(--color-white);
  line-height: 120%;
  white-space: pre-wrap;

  @media (min-width: 1920px) {
    font-size: 58px;
  }

  @media (max-width: 1620px) {
    font-size: 44px;
  }

  @media (max-width: 1450px) {
    font-size: 40px;
  }

  @media (max-width: 1250px) {
    font-size: 32.5px;
  }
`;

const Subtext = styled.div`
  font-size: 25px;
  font-weight: 400;
  color: var(--color-white);
  margin-bottom: 6%;
  padding: 0;
  white-space: pre-wrap;

  & p {
    padding-top: 2%;
    width: 90%;
  }

  @media (max-width: 1620px) {
    font-size: 23px;
  }

  @media (max-width: 1450px) {
    font-size: 20px;
  }

  @media (max-width: 1250px) {
    font-size: 19px;
  }
`;

const RequestDemoButton = styled.button`
  color: var(--color-white);
  width: 230px;

  @media (max-width: 1450px) {
    width: 210px;
    font-size: 18px;
  }
`;

const ImageWrapper = styled.div`
  width: 40%;
  padding-top: 40.57%;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  height: 0;
  margin: auto;

  @media (max-width: 1023px) {
    width: 60%;
    padding-top: 60.86%;
  }

  @media (max-width: 550px) {
    width: 90%;
    padding-top: 91.28%;
  }
`;

const BackgroundBubble = styled.div`
  background-image: ${bubbleSvg(lightTeal, 30)};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const HeaderImage = styled.div`
  background-image: url("${(props) => props.url}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  width: 95%;
  height: 95%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

// this code was used for when the image in the screen was replacable

// const ImageWrapper = styled.div`
//   width: 40%;
//   padding-top: 40.57%;
//   position: relative;
//   perspective: min(120vw, calc(1.2 * 1920px));
//   flex-shrink: 0;
//   flex-grow: 0;
//   height: 0;
// `;

// const TeaserImageMonitor = styled.div`
//   background-image: url(/img/mockup_teaser_with_icon_right.png);
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position-y: center;
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
// `;

// const ScreenContainer = styled.div`
//   background-image: url("${(props) => props.url}");
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position-y: center;
//   width: 77%;
//   height: 65%;
//   position: absolute;
//   left: 5.1%;
//   right: 0;
//   top: 7.6%;
//   bottom: 0;
//   transform: rotate3d(-0.75,1.55,-0.214,-46.7deg); //(x,y,z,angle)
// `;

const TeaserContainerMobile = styled(TeaserContainer)`
  flex-direction: column;
  padding-top: 6%;

  @media (min-width: 1024px) {
    display: none;
  }

  @media (max-width: 1023px) {
    display: flex;
  }

`;

const HeadlineMobile = styled.h1`
  font-size: 49px;
  text-align: center;
  white-space: pre-line;
  line-height: 120%;
  color: var(--color-white);
  margin-top: 0;
  padding-bottom: 4%;
  width: 95%;
  margin: auto;

  & p {
    margin: 0;
  }

  @media (max-width: 900px) {
    font-size: 44px;
  }

  @media (max-width: 800px) {
    font-size: 38px;
  }

  @media (max-width: 700px) {
    font-size: 29px;
  }

  @media (max-width: 550px) {
    font-size: 24px;
  }

  @media (max-width: 440px) {
    font-size: 20px;
  }

  @media (max-width: 370px) {
    width: 100%;
    font-size: 18px;
  }
`;

// this code was used for when the image in the screen was replacable

// const ImageWrapperMobile = styled.div`
//   width: 60%;
//   padding-top: 60.86%;
//   position: relative;
//   perspective: min(180vw, calc(1.8 * 1920px));
//   flex-shrink: 0;
//   flex-grow: 0;
//   height: 0;
//   margin: auto;

//   @media (max-width: 550px) {
//     width: 90%;
//     padding-top: 91.28%;
//     perspective: min(270vw, calc(2.7 * 1920px));
//   }
// `;

// const TeaserImageMonitorMobile = styled.div`
//   background-image: url(/img/mockup_teaser_with_icon_right.png);
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position-y: center;
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
// `;

// const ScreenContainerMobile = styled.div`
//   background-image: url("${(props) => props.url}");
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position-y: center;
//   width: 77%;
//   height: 65%;
//   position: absolute;
//   left: 5.1%;
//   right: 0;
//   top: 7.6%;
//   bottom: 0;
//   transform: rotate3d(-0.75,1.55,-0.214,-46.7deg); //(x,y,z,angle)
// `;

const SubtextMobile = styled.div`
  font-size: 20px;
  max-width: 90%;
  margin: auto;
  font-weight: 400;
  color: var(--color-white);
  white-space: pre-wrap;

  & p {
    padding-top: 4%;
  }

  @media (max-width: 900px) {
    font-size: 18px;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    white-space: normal;
    text-align: center;
  }
`;

const RequestDemoButtonMobile = styled.button`
  width: 230px;
  margin-top: 3%;
  margin-left: 5%;
  color: var(--color-white);
  

  @media (max-width: 900px) {
    font-size: 18px;
    width: 220px;
  }

  @media (max-width: 550px) {
    font-size: 16px;
    width: 190px;
    margin: auto;
    margin-top: 3%;
  }

  @media (max-width: 440px) {
    font-size: 15px;
    width: 180px;
  }
`;

export default class Teaser extends Component {
  static propTypes = {
    data: PropTypes.object,
    locale: PropTypes.string,
  };

  constructor(...params) {
    super(...params);
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.onResize);

      window.setTimeout(this.onResize, 100);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
      if (this.resizeTimeout) {
        window.clearTimeout(this.resizeTimeout);
        this.resizeTimeout = null;
      }
    }
  }

  onResize = () => {
    if (typeof window !== 'undefined') {
      if (this.resizeTimeout) {
        window.clearTimeout(this.resizeTimeout);
        this.resizeTimeout = null;
      }
      this.resizeTimeout = window.setTimeout(() => {
        this.forceUpdate();
      }, 500);
    }
  };

  render() {
    const { data, locale } = this.props;

    return (
      <header>
        <TeaserContainer>
          <TextContainer>
            <Headline>
              { data?.headlineTeaser || 'HEADLINE NOT FOUND'}
            </Headline>
            <Subtext>
              <MDXRenderer>{ data?.subtextTeaser || 'SUBTEXT NOT FOUND'}</MDXRenderer>
            </Subtext>
            <RequestDemoButton
              type="button"
              onClick={() => {
                navigate(localizedPath({
                  locale,
                  path: `/${data?.buttonlink}`,
                  defaultLang: 'de',
                  prefixDefault: false,
                }));
              }}
            >
              { data?.buttontext || 'BUTTONTEXT NOT FOUND' }
            </RequestDemoButton>
          </TextContainer>
          <ImageWrapper>
            <BackgroundBubble />
            <HeaderImage url={data?.teaserImage} />
            {/* <ScreenContainer url={data?.teaserImageScreen} />
            <TeaserImageMonitor /> */}
          </ImageWrapper>
        </TeaserContainer>
        <TeaserContainerMobile>
          <HeadlineMobile>
            { data?.headlineTeaser || 'HEADLINE NOT FOUND'}
          </HeadlineMobile>
          {/* <ImageWrapperMobile>
            <BackgroundBubble />
            <ScreenContainerMobile url={data?.teaserImageScreen} />
            <TeaserImageMonitorMobile />
          </ImageWrapperMobile> */}
          <ImageWrapper>
            <BackgroundBubble />
            <HeaderImage url={data?.teaserImage} />
          </ImageWrapper>
          <SubtextMobile>
            <MDXRenderer>{ data?.subtextTeaser || 'SUBTEXT NOT FOUND'}</MDXRenderer>
          </SubtextMobile>
          <RequestDemoButtonMobile
            type="button"
            onClick={() => {
              navigate(localizedPath({
                locale,
                path: `/${data?.buttonlink}`,
                defaultLang: 'de',
                prefixDefault: false,
              }));
            }}
          >
            { data?.buttontext || 'BUTTONTEXT NOT FOUND' }
          </RequestDemoButtonMobile>
        </TeaserContainerMobile>
      </header>
    );
  }
}
