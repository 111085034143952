import React, { Component } from 'react';
import { Slide } from 'react-slideshow-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { localizedPath } from 'gatsby-theme-i18n/src/helpers';

import { bubbleSvg } from '../helpers';
import { blue, lightTeal, magenta } from '../colors';

const CustomerReferencesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${(props) => (props.extraStylingHeadline ? '3%' : '10%')};
  padding-bottom: ${(props) => (props.extraStylingHeadline ? '5%' : '20%')};
  position: relative;
  z-index: 1;
  max-width: 1920px;
  margin: auto;
  margin-bottom: ${(props) => (props.extraStylingHeadline ? '0' : '-12%')};

  @media (min-width: 1921px) {
    padding-bottom: ${(props) => (props.extraStylingHeadline ? '5%' : '20%')};
  }

  @media (max-width: 1550px) {
    padding-bottom: ${(props) => (props.extraStylingHeadline ? '10%' : '20%')};
  }

  @media (max-width: 1350px) {
    padding-bottom: ${(props) => (props.extraStylingHeadline ? '15%' : '20%')};
  }

  @media (max-width: 1023px) {
    flex-direction: column;
  }

  @media (max-width: 610px) {
    padding-bottom: 40%;
  }

  @media (max-width: 462px) {
    padding-bottom: ${(props) => (props.extraStylingHeadline ? '50%' : '45%')};
  }

  @media (max-width: 380px) {
    padding-bottom: ${(props) => (props.extraStylingHeadline ? '70%' : '65%')};
  }
`;

const CustomerReferencesSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & div {
    flex: 100%;
  }

  @media (max-width: 2120px) {
    margin-left: 100px;
  }

  @media (min-width: 1777px) {
    width: 90%;
  }

  @media (max-width: 1350px) {
    width: 98%;
  }

  @media (max-width: 1370px) {
    margin-left: 50px;
  }

  @media (max-width: 1150px) {
    width: 100%;
  }

  @media (max-width: 1023px) {
    padding-top: 5%;
    justify-content: space-around;
    margin-left: 0;
  }

  @media (max-width: 550px) {
    justify-content: center;
  }
`;

const Image = styled.figure`
  height: 150px;
  width: 120px;
  margin-top: 0;
  display: flex;
  justify-content: end;
  align-items: center;


  & img {
    height: 75px;

    @media (max-width: 1350px) {
      height: 60px;
    }

    @media (max-width: 1023px) {
      height: 70px;
    }
  
    @media (max-width: 550px) {
      height: 60px;
    }
  }

  @media (max-width: 1620px) {
    height: 100px;
  }

  @media (max-width: 1350px) {
    width: 70px;
    height: 70px;
  }

  @media (max-width: 1023px) {
    width: 18%;
    height: 100px;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 550px) {
    width: 16%;
  }

  @media (max-width: 461px) {
    width: 20%;
    align-items: center;
    height: 80px;
  }

  @media (max-width: 400px) {
    width: 22%;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  margin: auto;
  margin-left: 0;

  @media (max-width: 2120px) {
    padding-left: 100px;
  }

  @media (max-width: 1370px) {
    padding-left: 50px;
  }

  @media (max-width: 1023px) {
    width: 90%;
    margin: auto;
    padding-left: 0;
  }

  @media (max-width: 550px) {
    width: 95%;
  }

`;

const Headline = styled.h2`
  font-size: ${(props) => (props.extraStylingHeadline ? '32px' : '60px')};
  color: ${(props) => (props.extraStylingHeadline ? 'var(--color-white)' : 'var(--color-yellow)')};
  text-align: left;
  white-space: pre-wrap;
  margin-top: 0;
  margin-bottom: 0;

  & p {

    @media (max-width: 1250px) {
      white-space: normal;
    }

    @media (max-width: 1023px) {
      white-space: unset;
    }
  }

  @media (max-width: 1620px) {
    font-size: ${(props) => (props.extraStylingHeadline ? '32px' : '55px')};
  }

  @media (max-width: 1450px) {
    font-size: ${(props) => (props.extraStylingHeadline ? '32px' : '47px')};
  }

  @media (max-width: 1250px) {
    font-size: ${(props) => (props.extraStylingHeadline ? '28px' : '44px')};
  }

  @media (max-width: 1023px) {
    white-space: pre-line;
    text-align: center;
    font-size: ${(props) => (props.extraStylingHeadline ? '28px' : '50px')};
  }

  @media (max-width: 900px) {
    font-size: ${(props) => (props.extraStylingHeadline ? '24px' : '50px')};

  }

  @media (max-width: 700px) {
    font-size: ${(props) => (props.extraStylingHeadline ? '20px' : '50px')};
  }

  @media (max-width: 550px) {
    font-size: ${(props) => (props.extraStylingHeadline ? '18px' : '29px')};
  }
  `;

const Subtext = styled.div`
  font-size: 20px;
  color: var(--color-yellow);
  text-align: left;
  white-space: pre-wrap;
  margin-top: 0;

  & p {
    margin-top: 0;
    padding-top: 20px;
  }

  @media (max-width: 1450px) {
    font-size: 19px;
  }

  @media (max-width: 1023px) {
    font-size: 20px;
    text-align: center;
    width: 85%;
    margin: auto;
  }

  @media (max-width: 900px) {
    font-size: 18px;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    width: 100%;
    white-space: normal;
  }
`;

const ImageWrapper = styled.div`
  width: 45%;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  height: 0;
  padding-top: 37.43%;
  margin: auto;

  @media (max-width: 1023px) {
    width: 100%;
    padding-top: 63.64%;
  }
`;

const BackgroundBubble = styled.div`
  background-image: ${bubbleSvg(lightTeal, 25)};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  width: 110%;
  height: 110%;
  left: 10%;
  right: 0;
  top: 0;
  bottom: 0;

  @media (max-width: 1550px) {
    width: 120%;
    height: 120%;
    left: 0;
  }

  @media (max-width: 1350px) {
    width: 130%;
    height: 130%;
    left: -5%;
  }

  @media (max-width: 1095px) {
    width: 135%;
    height: 135%;
  }

  @media (max-width: 1023px) {
    width: 120%;
    height: 120%;
    left: -10%;
  }

  @media (max-width: 580px) {
    width: 160%;
    height: 160%;
    left: -30%;
  }

  @media (max-width: 450px) {
    width: 180%;
    height: 180%;
    left: -39%;
  }

  @media (max-width: 380px) {
    width: 220%;
    height: 220%;
    left: -60%;
    top: -3%;
  }
`;

const QuotesBubble = styled.div`
  background-image: ${bubbleSvg(blue, 25)};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  width: 20%;
  height: 20%;
  left: 60%;
  right: 0;
  top: 2%;
  bottom: 0;

  @media (max-width: 1550px) {
    left: 55%;
  }

  @media (max-width: 1023px) {
    left: 43%;
  }

  @media (max-width: 580px) {
    left: 40%;
  }

  @media (max-width: 380px) {
    width: 24%;
    height: 24%;
    left: 38%;
  }
`;

const QuotationImage = styled.div`
  background-image: url(/img/quotations.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  width: 8%;
  height: 8%;
  left: 66.5%;
  right: 0;
  top: 8%;
  bottom: 0;

  @media (max-width: 1550px) {
    left: 61.5%;
  }

  @media (max-width: 1023px) {
    left: 49.5%;
  }

  @media (max-width: 580px) {
    left: 46.5%;
  }

  @media (max-width: 380px) {
    top: 10%;
  }
`;

const StarBubble = styled.div`
  background-image: ${bubbleSvg(magenta, 25)};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  width: 10%;
  height: 10%;
  left: 75%;
  right: 0;
  top: 12%;
  bottom: 0;

  @media (max-width: 1550px) {
    left: 70%;
  }

  @media (max-width: 1023px) {
    left: 56%;
  }

  @media (max-width: 580px) {
    left: 53%;
  }

  @media (max-width: 380px) {
    width: 14%;
    height: 14%;
    left: 54%;
    top: 15%;
  }
`;

const StarImage = styled.div`
  background-image: url(/img/star.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  width: 4%;
  height: 4%;
  left: 78.1%;
  right: 0;
  top: 14.7%;
  bottom: 0;

  @media (max-width: 1550px) {
    left: 73.1%;
  }

  @media (max-width: 1023px) {
    left: 59.1%;
  }

  @media (max-width: 580px) {
    left: 56.1%;
  }

  @media (max-width: 380px) {
    width: 6%;
    height: 6%;
    left: 58.2%;
    top: 18.5%;
  }
`;

const SlideContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 10%;
  bottom: 0;
  right: 0;

  & .react-slideshow-wrapper.slide {
    width: 60%;
    margin-left: 27%;

    @media (max-width: 1550px) {
      margin-left: 22%;
    }
    
    @media (max-width: 1095px) {
      width: 80%;
      margin-left: 15%;
    }

    @media (max-width: 1023px) {
      width: 55%;
    }

    @media (max-width: 580px) {
      width: 65%;
      margin-left: 8%;
    }

    @media (max-width: 470px) {
      width: 76%;
      margin-left: 4%;
    }

    @media (max-width: 380px) {
      width: 95%;
      margin-left: -9%;
    }
  }

  & .react-slideshow-container+ul.indicators li {
    padding-left: 10px;
    padding-right: 10px;

    @media (max-width: 620px) {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  & .react-slideshow-container+ul.indicators .each-slideshow-indicator:before {
    width: 9px;
    height: 7px;

    @media (max-width: 700px) {
      width: 7px;
      height: 5px;
    }
  }

  & ul {
    padding-inline-start: 0;
  }


  & .react-slideshow-container+ul.indicators {
    margin-top: -19%;
    margin-left: 14%;

    @media (max-width: 1700px) {
      margin-top: -29%;
    }

    @media (max-width: 1550px) {
      margin-left: 4%;
    }

    @media (max-width: 1450px) {
      margin-top: -36%;
    }

    @media (max-width: 1350px) {
      margin-top: -40%;
    }

    @media (max-width: 1250px) {
      margin-top: -48%;
    }

    @media (max-width: 1175px) {
      margin-top: -54%;
    }

    @media (max-width: 1125px) {
      margin-top: -58%;
    }

    @media (max-width: 1095px) {
      margin-top: -65%;
    }

    @media (max-width: 1045px) {
      margin-top: -68%;
    }

    @media (max-width: 1023px) {
      margin-top: -17%;
      margin-left: -15.5%;
    }

    @media (max-width: 885px) {
      margin-top: -27%;
    }

    @media (max-width: 795px) {
      margin-top: -31%;
    }

    @media (max-width: 760px) {
      margin-top: -40%;
    }

    @media (max-width: 700px) {
      margin-top: -23%;
    }

    @media (max-width: 620px) {
      margin-top: -29%;
    }

    @media (max-width: 580px) {
      margin-top: -27%;
      margin-left: -19%;
    }

    @media (max-width: 475px) {
      margin-top: -33%;
    }

    @media (max-width: 470px) {
      margin-top: -38%;
      margin-left: -16%;
    }

    @media (max-width: 400px) {
      margin-top: -44%;
    }

    @media (max-width: 380px) {
      margin-top: -46%;
      margin-left: -24%;
    }

    @media (max-width: 335px) {
      margin-top: -51%;
    }
  }
`;

const CustomerQuotationArea = styled.div`
  position: relative;
  height: 700px;

  @media (max-width: 700px) {
    height: 500px;
   }
`;

const CustomerLogo = styled.div`
  background-image: url("${(props) => props.url}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 13%;
  position: absolute;
  top: 21%;
  bottom: 0;
  left: 0;
  right: 0;

  @media (max-width: 1700px) {
    top: 19%;
  }

  @media (max-width: 1450px) {
    top: 16%;
    height: 12%;
  }

  @media (max-width: 1250px) {
    top: 14%;
    height: 11%;
  }

  @media (max-width: 1125px) {
    top: 13%
  }

  @media (max-width: 1095px) {
    top: 12%;
  }
  
  @media (max-width: 1023px) {
    top: 20%;
    height: 17%;
  }

  @media (max-width: 885px) {
    top: 18%;
    height: 12%;
  }

  @media (max-width: 760px) {
    top: 15%;
  }

  @media (max-width: 700px) {
    top: 19%;
  }

  @media (max-width: 620px) {
    top: 17%;
  }

  @media (max-width: 580px) {
    height: 14%;
  }

  @media (max-width: 470px) {
    top: 13%;
    height: 12%;
  }

  @media (max-width: 400px) {
    top: 16%;
  }

  @media (max-width: 380px) {
    height: 13%;
  }

`;

const Quotation = styled.p`
  width: 100%;
  font-size: 18px;
  color: var(--color-dark-grey);
  text-align: center;
  white-space: pre-wrap;
  position: absolute;
  top: 35%;
  left: 0;
  
  @media (max-width: 1700px) {
    top: 31%;
  }

  @media (max-width: 1450px) {
    top: 29%;
    font-size: 17px;
    white-space: normal; 
  }

  @media (max-width: 1250px) {
    top: 25%;
    font-size: 16px;
  }

  @media (max-width: 1175px) {
    top: 24%;
  }

  @media (max-width: 1125px) {
    top: 22%;
  }

  @media (max-width: 1095px) {
    top: 22%
  }
  
  @media (max-width: 1023px) {
    top: 37%;
    font-size: 20px;
  }

  @media (max-width: 885px) {
    top: 30%;
    font-size: 17px;
  }

  @media (max-width: 760px) {
    top: 26%;
    font-size: 16px;
  }

  @media (max-width: 700px) {
    top: 30%;
    font-size: 14px;
  }

  @media (max-width: 620px) {
    top: 28%;
    font-size: 13px;
  }

  @media (max-width: 580px) {
    top: 30%;
    font-size: 14px;
  }

  @media (max-width: 470px) {
    top: 28%;
    font-size: 13px;
  }

  @media (max-width: 400px) {
    top: 25%;
  }

  @media (max-width: 380px) {
    top: 31%;
  }
`;

const CustomerName = styled.p`
  width: 100%;
  font-size: 16px;
  font-style: italic;
  color: var(--color-dark-grey);
  text-align: center;
  white-space: pre-wrap;
  position: absolute;
  top: 53%;
  left: 0;

  @media (max-width: 1700px) {
    top: 50%;
  }

  @media (max-width: 1450px) {
    top: 48%;
    font-size: 15px;
  }

  @media (max-width: 1250px) {
    top: 43%;
    font-size: 14px;
  }

  @media (max-width: 1175px) {
    top: 41%;
  }

  @media (max-width: 1125px) {
    top: 40%;
  }

  @media (max-width: 1095px) {
    top: 37%;
  }

  @media (max-width: 1023px) {
    top: 55%;
    font-size: 17px;
  }

  @media (max-width: 885px) {
    top: 47%;
    font-size: 15px;
  }

  @media (max-width: 760px) {
    top: 41%;
    font-size: 14px;
  }

  @media (max-width: 700px) {
    top: 48%;
    font-size: 13px;
  }

  @media (max-width: 620px) {
    top: 45%;
    font-size: 12px;
  }

  @media (max-width: 580px) {
    top: 50%;
  }

  @media (max-width: 470px) {
    top: 46%;
  }

  @media (max-width: 400px) {
    top: 43%;
  }

  @media (max-width: 380px) {
    top: 46%;
  }

  @media (max-width: 335px) {
    top: 48%;
  }
`;

const ReadMoreButton = styled.button`
  color: var(--color-dark-grey);
  font-size: 17px;
  width: 160px;
  margin: auto;
  display: flex;
  justify-content: center;
  top: 68%;

  @media (max-width: 1700px) {
    top: 63%;
  }

  @media (max-width: 1450px) {
    top: 61%;
    font-size: 16px;
    width: 140px;
  }

  @media (max-width: 1250px) {
    top: 57%;
  }

  @media (max-width: 1175px) {
    top: 55%;
  }

  @media (max-width: 1125px) {
    top: 54%;
  }

  @media (max-width: 1095px) {
    top: 49%;
  }
  
  @media (max-width: 1023px) {
    top: 69%;
    font-size: 18px;
    width: 160px;
  }

  @media (max-width: 885px) {
    top: 60%;
    font-size: 16px;
    width: 140px;
  }

  @media (max-width: 760px) {
    top: 52%;
  }

  @media (max-width: 700px) {
    top: 62%;
  }

  @media (max-width: 620px) {
    font-size: 14px;
    width: 130px;
    top: 58%;
  }

  @media (max-width: 580px) {
    top: 63%;
   }

  @media (max-width: 470px) {
    top: 59%;
  }

  @media (max-width: 400px) {
    top: 59%;
  }

  @media (max-width: 335px) {
    top: 61%;
  }
`;

export default class CustomerReferences extends Component {
  static propTypes = {
    data: PropTypes.object,
    locale: PropTypes.string,
  };

  render() {
    const { data, locale } = this.props;

    const properties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: false,
    };

    return (
      <section>
        <CustomerReferencesContainer extraStylingHeadline={data?.extraStylingHeadline}>
          <CustomerReferencesSection>
            <TextContainer>
              <Headline extraStylingHeadline={data?.extraStylingHeadline}>
                <MDXRenderer>{data?.customerReferenceHeadline}</MDXRenderer>
              </Headline>
              { data?.customerReferenceSubtext && (
                <Subtext>
                  <MDXRenderer>{data?.customerReferenceSubtext}</MDXRenderer>
                </Subtext>
              )}
            </TextContainer>
            <ImageContainer>
              {data?.customerReferenceImages.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Image key={index}>
                  <img src={item.customerReferenceImage} alt={item.altAttributeCustomerImage} loading="lazy" />
                </Image>
              ))}
            </ImageContainer>
          </CustomerReferencesSection>
          <ImageWrapper>
            <BackgroundBubble />
            <QuotesBubble />
            <QuotationImage />
            <StarBubble />
            <StarImage />
            <SlideContainer>
              <Slide {...properties}>
                {data?.customerQuotations.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <CustomerQuotationArea key={index}>
                    <CustomerLogo url={item?.customerLogo} />
                    <Quotation>
                      {item?.customerQuotation}
                    </Quotation>
                    <CustomerName>
                      {item?.customerName}
                    </CustomerName>
                    <ReadMoreButton
                      type="button"
                      onClick={() => {
                        navigate(localizedPath({
                          locale,
                          path: `/${item?.buttonLink}/`,
                          defaultLang: 'de',
                          prefixDefault: false,
                        }));
                      }}
                    >
                      {item?.readMoreButton}
                    </ReadMoreButton>
                  </CustomerQuotationArea>
                ))}
              </Slide>
            </SlideContainer>
          </ImageWrapper>
        </CustomerReferencesContainer>
      </section>
    );
  }
}
