import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import styled from 'styled-components';

import '../globalStyles.css';
import { bubbleSvg } from '../helpers';
import {
  lightBlue, lightTeal, lightYellow, lightYellowOpacity70, teal,
} from '../colors';
import Layout from '../components/Layout';
import Teaser from '../components/Teaser';
import CustomerReferences from '../components/CustomerReferences';
// import Whitepaper from '../components/Whitepaper';
import TrustFacts from '../components/TrustFacts';
import Advantages from '../components/Advantages';
import Features from '../components/Features';
import RequestDemo from '../components/RequestDemo';
import Newsletter from '../components/Newsletter';
import PartnerReferences from '../components/PartnerReferences';
import ThisIsPresono from '../components/ThisIsPresono';

const numberOfBubbles = 50;
const numberOfBubblesTablet = 35;
const numberOfBubblesMobile = 25;

const getRandomValue = (min, max) => ((max - min) * Math.random() + min);

const getRandomPosition = () => {
  const randomPositionArray = [];

  for (let i = 0; i < numberOfBubbles; i += 1) {
    randomPositionArray.push(`${getRandomValue(0, 100)}% ${getRandomValue(0, 100)}%`);
  }
  return randomPositionArray.join(', ');
};

const getRandomSize = () => {
  const randomSizeArray = [];

  for (let i = 0; i < numberOfBubbles; i += 1) {
    randomSizeArray.push(`${getRandomValue(10, 30)}px`);
  }
  return randomSizeArray.join(', ');
};

const getRandomSizeTablet = () => {
  const randomSizeArray = [];

  for (let i = 0; i < numberOfBubblesTablet; i += 1) {
    randomSizeArray.push(`${getRandomValue(10, 20)}px`);
  }
  return randomSizeArray.join(', ');
};

const getRandomSizeMobile = () => {
  const randomSizeArray = [];

  for (let i = 0; i < numberOfBubblesMobile; i += 1) {
    randomSizeArray.push(`${getRandomValue(5, 15)}px`);
  }
  return randomSizeArray.join(', ');
};

const getRandomBubble = () => {
  const randomBubblesArray = [];

  for (let i = 0; i < numberOfBubbles; i += 1) {
    randomBubblesArray.push(`${bubbleSvg(lightYellowOpacity70, getRandomValue(0, 90))}`);
  }
  return randomBubblesArray.join(', ');
};

const HomeContainer = styled.div`
  position: relative;
  background-color: var(--color-white);
  overflow-x: hidden;
`;

const BackgroundSectionBubbles = styled.div`
  background-image: ${getRandomBubble()};
  background-repeat: no-repeat;
  background-position: ${getRandomPosition()};
  background-size: ${getRandomSize()};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 1350px) {
    background-size: ${getRandomSizeTablet()};
  }

  @media (max-width: 550px) {
    background-size: ${getRandomSizeMobile()};
  }
`;

const BackgroundSection1 = styled.div`
  background-color: var(--color-teal);
  position: relative;
`;

const BackgroundSection2 = styled.div`
  background-image: ${bubbleSvg(teal, 10)};
  background-size: 200%;
  background-position: 35% 89%;
  background-repeat: no-repeat;

  @media (max-width: 1023px) {
    background-size: 260%;
    background-position: 30% 85%;
  }

  @media (max-width: 715px) {
    background-position: 33% 80%;
  }

  @media (max-width: 610px) {
    background-size: 310%;
    background-position: 33% 77%;
  }

  @media (max-width: 490px) {
    background-position: 31% 76%;
  }

  @media (max-width: 461px) {
    background-image: ${bubbleSvg(teal, 8)};
    background-size: 400%;
    background-position: 28% 73%;
  }

  @media (max-width: 380px) {
    background-size: 420%;
  }

  @media (max-width: 330px) {
    background-size: 460%;
  }
`;

const BackgroundSection3 = styled.div`
  position: relative;
  background-image: ${bubbleSvg(lightTeal, 15)};
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 133%;

  @media (min-width: 2100px) {
    background-size: 125% 3000px;
  }

  @media (max-width: 1800px) {
    background-size: 138%;  
  }

  @media (max-width: 1620px) {
    background-size: 149%;
  }

  @media (max-width: 1450px) {
    background-size: 157%;
  }

  @media (max-width: 1350px) {
    background-size: 147%;
  }

  @media (max-width: 1250px) {
    background-size: 166%;
  }

  @media (max-width: 1023px) {
    background-image: none;
  }

`;

const BackgroundSection4 = styled.div`
  position: relative;
  background-image: ${bubbleSvg(lightBlue, -5)};
  background-position: 50% 75%;
  background-repeat: no-repeat;

  @media (min-width: 2701px) {
    background-size: 98%;
  }

  @media (max-width: 2700px) {
    background-size: 101%;
    background-position: 50% 76%;
  }

  @media (max-width: 2560px) {
    background-size: 103%;
  }

  @media (max-width: 2430px) {
    background-size: 110%;
    background-position: 50% 62%;
  }

  @media (max-width: 2260px) {
    background-size: 114%;
  }

  @media (max-width: 2160px) {
    background-size: 118%;
  }

  @media (max-width: 1920px) {
    background-size: 125%;
    background-position: 50% 64%;
  }

  @media (max-width: 1650px) {
    background-size: 128%;
    background-position: 50% 65%;
  }

  @media (max-width: 1400px) {
    background-size: 135%;
    background-position: 50% 68%;
  }

  @media (max-width: 1200px) {
    background-size: 148%;
    background-position: 50% 63%;
  }

  @media (max-width: 1100px) {
    background-size: 151%;
  }

  @media (max-width: 1023px) {
    background-image: none;
  }

`;

const BackgroundSection5 = styled.div`
  background-image: ${bubbleSvg(lightYellow, 44)};
  background-size: 900%;
  background-position: 50% 2%;
  background-repeat: no-repeat;

  @media (max-width: 550px) {
    background-position: 49.5% 2.3%;
  }

`;

export default class Home extends Component {
  static propTypes = {
    data: PropTypes.object,
    pageContext: PropTypes.object,
  };

  render() {
    const { data, pageContext } = this.props;

    let canonicalLinkPrefix;

    if (pageContext.locale === 'en') {
      canonicalLinkPrefix = 'https://presono.com/en';
    } else {
      canonicalLinkPrefix = 'https://presono.com';
    }

    return (
      <Layout currentPath={pageContext.originalPath}>
        <Helmet>
          <title>{`${data?.mdx?.frontmatter?.title}  | presono`}</title>
          <meta
            name="description"
            content={data?.mdx?.frontmatter?.snippetTextIndex}
          />

          <link rel="canonical" href={canonicalLinkPrefix + pageContext.originalPath} />

          <meta property="og:title" content={data?.mdx?.frontmatter?.title} />
          <meta property="og:description" content={data?.mdx?.frontmatter?.snippetTextIndex} />
          <meta name="twitter:title" content={data?.mdx?.frontmatter?.title} />
          <meta name="twitter:description" content={data?.mdx?.frontmatter?.snippetTextIndex} />
        </Helmet>
        <HomeContainer tabIndex="-1">
          <BackgroundSection1>
            <Teaser data={data?.mdx?.frontmatter} locale={data?.mdx?.fields?.locale} />
            <ThisIsPresono data={data?.mdx?.frontmatter} />
          </BackgroundSection1>
          <BackgroundSection2>
            {/* <BubbleBackgroundSection2 /> */}
            <CustomerReferences data={data?.mdx?.frontmatter} locale={data?.mdx?.fields?.locale} />
          </BackgroundSection2>
          {/* <Videoblock data={data?.mdx?.frontmatter} /> */}
          <BackgroundSection3>
            {/* <BubbleBackgroundSection3 /> */}
            <Advantages data={data?.mdx?.frontmatter} />
            <Features data={data?.mdx?.frontmatter} />
          </BackgroundSection3>
          <BackgroundSection4>
            <TrustFacts data={data?.mdx?.frontmatter} />
            <RequestDemo data={data?.mdx?.frontmatter} locale={data?.mdx?.fields?.locale} />
          </BackgroundSection4>
          <PartnerReferences data={data?.mdx?.frontmatter} locale={data?.mdx?.fields?.locale} />
          <BackgroundSection5>
            <Newsletter data={data?.mdx?.frontmatter} locale={data?.mdx?.fields?.locale} />
          </BackgroundSection5>
          <BackgroundSectionBubbles />
        </HomeContainer>
      </Layout>
    );
  }
}

export const query = graphql`
query IndexQuery($locale: String) {
  mdx(
    fields: { locale: { eq: $locale } },
    frontmatter: { layout: { eq: "index" } }
  ) {
    frontmatter {
      title
      snippetTextIndex
      headlineTeaser
      subtextTeaser
      buttontext
      buttonlink
      teaserImage
      headlineThisIsPresono
      subtextThisIsPresono
      thisIsPresonoImage
      customerReferenceHeadline
      customerReferenceImages {
        customerReferenceImage
        altAttributeCustomerImage
      }
      customerReferenceSubtext
      customerQuotations {
        customerLogo
        customerQuotation
        customerName
        readMoreButton
        buttonLink
      }
      whitepaperHeadline
      whitepaperSubtext
      whitepaperButton
      whitepaperImage
      videoHomepage
      headlineTrustFacts
      subtextTrustFacts
      trustFacts {
        trustFactIcon
        altAttributeTrustFactIcon
        trustFact
      }
      advantagesHeadline
      advantagesSlideshow {
        advantagesImage
      }
      advantagesSubtext
      featuresHeadline
      featuresSlideshow {
        featuresImage
      }
      featuresSubtext
      requestDemoButton
      requestDemoHeadline
      requestDemoSubtext
      requestDemoImage
      altAttributeRequestDemoImage
      newsletterHeadline
      newsletterSubtext
      newsletterMailPlaceholder
      newsletterFirstNamePlaceholder
      newsletterLastNamePlaceholder
      subscribeButton
      partnerReferenceHeadline
      partnerReferenceImages {
        partnerReferenceImage
        altAttributePartnerImage
        linkToPartnerReference
        biggerImage
        smallerImage
      }
      partnerReferenceSubtext
      becomePartnerButton
    }
    fields {
      locale
    }
  }
}
`;
