import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from 'styled-components';

const SectionTrustFacts = styled.section`
  display: flex;
  padding-top: 8%;
  padding-bottom: 10%;
  position: relative;
  z-index: 1;
  max-width: 1920px;
  margin: auto;

  @media (max-width: 1023px) {
    flex-direction: column-reverse;
    padding-top: 5%;
    padding-bottom: 0;
    background-color: var(--color-light-blue);
  }
`;

const TextContainer = styled.div`
  width: 50%;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const Headline = styled.h2`
  font-size: 60px;
  margin-bottom: 0;
  padding-top: 5%;
  color: var(--color-white);
  line-height: 130%;
  text-align: left;
  white-space: pre-wrap;
  margin-bottom: 0;

  @media (min-width: 1921px) {
    padding-top: 15%;
  }

  @media (max-width: 2120px) {
    margin-left: 100px;
  }

  @media (max-width: 1620px) {
    font-size: 55px;
  }

  @media (max-width: 1370px) {
    font-size: 47px;
    margin-left: 50px;
  }

  @media (max-width: 1023px) {
    font-size: 50px;
    text-align: center;
    margin: auto;
  }

  @media (max-width: 550px) {
    font-size: 29px;
  }

`;

const Subtext = styled.div`
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  color: var(--color-white);
  margin-bottom: 6%;
  padding: 0;
  white-space: pre-wrap;

  & p {
    margin-top: 0;
    padding-top: 20px;
  }

  @media (max-width: 2120px) {
    margin-left: 100px;
  }

  @media (max-width: 1370px) {
    margin-left: 50px;
    font-size: 19px;
  }

  @media (max-width: 1023px) {
    margin: auto;
    margin-bottom: 20%;
    width: 75%;
    font-size: 18px;
  }

  @media (max-width: 850px) {
    width: 90%;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    text-align: center;
  }
`;

const TrustFactContainer = styled.div`
  display: flex;
  max-width: 50%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 12%;

  & div {
    flex: 50%;

    @media (max-width: 1023px) {
      flex: 0;
    }

    @media (max-width: 750px) {
      flex: 50%;
    }
  }

  @media (max-width: 2120px) {
    margin-right: 100px;
  }

  @media (max-width: 1620px) {
    margin-top: 14%;
  }

  @media (max-width: 1370px) {
    margin-top: 16%;
    margin-right: 50px;
  }

  @media (max-width: 1200px) {
    margin-top: 23%;
    margin-right: 25px;
  }

  @media (max-width: 1023px) {
    margin-right: 0;
    margin: auto;
    max-width: 90%;
    justify-content: space-around;
  }

  @media (max-width: 750px) {
    max-width: 100%;
  }

`;

const TrustFact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  height: 200px;
  width: 140px;

  & p {
    color: var(--color-white);
    font-size: 18px;
    white-space: pre-wrap;
    margin-top: 0;
    height: 50px;

    @media (max-width: 1023px){
      width: 185px;
      font-size: 17px;
    }

    @media (max-width: 750px) {
      margin: auto;
    }
  
    @media (max-width: 550px) {
      margin-top: 0;
      font-size: 14px;
      width: 162px;
    }
  }

  @media (max-width: 1500px) {
    width: 120px;
  }

  @media (max-width: 1200px) {
    height: 175px;
    width: 115px;
  }

  @media (max-width: 1023px) {
    width: 205px;
  }

  @media (max-width: 750px) {
    width: 150px;
  }

  @media (max-width: 550px) {
    height: 155px;
    width: 90px;
  }

  @media (max-width: 420px) {
    height: 130px;
  }
`;

const Icons = styled.img`
  filter: invert(1);
  width: 80px;
  margin: auto;

  @media (max-width: 900px) {
    width: 65px;
  }

  @media (max-width: 420px) {
    width: 55px;
  }
`;

export default class TrustFacts extends Component {
  static propTypes = {
    data: PropTypes.object,
  };

  render() {
    const { data } = this.props;

    return (
      <SectionTrustFacts>
        <TextContainer>
          <Headline>
            { data?.headlineTrustFacts || 'HEADLINE NOT FOUND'}
          </Headline>
          <Subtext>
            <MDXRenderer>{ data?.subtextTrustFacts || 'SUBTEXT NOT FOUND'}</MDXRenderer>
          </Subtext>
        </TextContainer>
        <TrustFactContainer>
          {data?.trustFacts.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <TrustFact key={index}>
              <Icons src={item.trustFactIcon} alt={item.altAttributeTrustFactIcon} loading="lazy" />
              <p>{item.trustFact}</p>
            </TrustFact>
          ))}
        </TrustFactContainer>
      </SectionTrustFacts>
    );
  }
}
